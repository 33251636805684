<template>
  <div>
          <h5 class="mt-1 mb-25 font-weight-bolder">
        <b-avatar
        class="m-auto"
        :variant="`light-${color}`"
        size="40"
        :text="statistic"
      >
      </b-avatar> 
      </h5>
      <span>{{ statisticTitle }}</span>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    statistic: {
      type: String,
      default: '0',
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
